import { useEffect, useState } from "react";
import { isOneOf, isString } from "../../nonComponentFunctions/helper_functions";
import { RowGrid } from "../divider and frames/layouts";
import { Modal } from "../modals/modal";
import { HeaderSmall, HeaderTiny, Indent } from "../text_display/main_content_text_styling";
import { CircleLoader } from "../widgets/loader";
import styled from "styled-components";
import { useAuth } from "../../authentication/auth_provider";
import { ErrorMessage } from "../themes and styles/error";
import { useProposalSelectionMapping } from "../variablenmanual/contexts";
import { DownloadProposalButton } from "../buttons/menu_area_buttons";


function Error({message}){
  if (isString(message) && message !== ""){

    return (
      <>
        <p>
          Available error message:
        </p>
        <Indent margin_left={"24px"}>
          <ErrorMessage>
            {message}
          </ErrorMessage>
        </Indent>
    </>
    )
  }
}

export function ProposalSubmitModal({closeModalCallback, proposal, mode}){
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');
  const {mail, token, role} = useAuth();
  const [request_sent, set_request_send] = useState(false);
  const {selectionManager} = useProposalSelectionMapping();

  useEffect(()=>{

    !request_sent && processProposal(processingCallback) && set_request_send(true);
  },[])

  const processingCallback = (proposal) => {
    if (proposal.db_response === undefined || proposal.db_response === null) {
      setStatus('error');
      setMessage('No response from database server. Please try again later.');
      return;
    }
    if (proposal.db_response.status === 'request_success') {
      processVariables(proposal);
      return;
    }
    setStatus('error');
    setMessage(proposal.db_response.message);
  }

  async function processVariables(proposal){
    const requestor_mail = (((mode === 'nachtragen') && mail)||proposal.getProposalAttribute('USER'));
    try{
      await selectionManager.db_set_variables_for_proposal(requestor_mail, token, proposal.getProposalAttribute('id'), null);
      setStatus('success');
    }
    catch (error){
      setStatus('error');
      setMessage(error.message);
    }
  }

  async function processProposal(callback){
    
    try{
      if (mode !== 'nachtragen' && isOneOf(proposal.getProposalAttribute('USER'), [null, undefined, "", false]))
      {
        proposal.setProposalAttribute('USER', mail);
      }
      let response_proposal = null;
      if(mode === 'nachtragen'){
        response_proposal = await proposal.db_proposal_nachtragen(mail, token);
      }
      else {
        response_proposal = await proposal.db_create_proposal(mail, token);
      }
      callback(response_proposal);
    }
    catch (error){
      setStatus('error');
      setMessage(error.message);
    }
  }

  return (
    <Modal closeModalCallback={closeModalCallback}>
      <RowGrid>
        {
          ((status === 'loading') && 
            <>
              <HeaderTiny text={"Processing Proposal"} />
              <CircleLoader/>
            </>) ||
          ((status === 'error') &&
            <>
              <HeaderTiny text={"Processing Proposal"} />
              <Indent margin_left={"24px"}>
                Something went wrong and processing of proposal failed.
                <Error message={message}/>
              </Indent>
            </>
          ) ||
          ((status === 'success') && 
            <>
              <HeaderTiny text={"Processing Proposal"} />
              <Indent margin_left={"24px"}>
                Proposal has been uploaded and selected Variables got associated with proposal
                  <DownloadProposalButton proposal={proposal}/>
              </Indent>
            </>)
          
        }
      </RowGrid>
    </Modal>
  )
}
