import { List, Map, Set } from "immutable";
import {
  isString,
  isList,
} from "./helper_functions";
import { Link } from "./Link";
import { Node } from "./node";

export const exampleGraph = {
  nodes: [
    {
      id: "testRoot",
      details: [{ attributeName: "details" }, { attributeName: "name" }],
    },
    {
      id: "node1",
      details: [{ attributeName: "details" }],
    },
    {
      id: "node2",
      details: [{ attributeName: "details" }],
    },
    {
      id: "node3",
      details: [{ attributeName: "details" }],
    },
    {
      id: "destination",
      details: [{ attributeName: "details" }],
    },
    {
      id: "vf_uv_045_5",
      details: [
        { attributeName: "Wissenschaftler" },
        { attributeName: "Ebene 1" },
        { attributeName: "Langname" },
      ],
    },
  ],
  links: [
    {
      source: "testRoot",
      target: "node1",
      value: 1,
    },
    {
      source: "node2",
      target: "destination",
      value: 1,
    },
    {
      source: "node2",
      target: "testRoot",
      value: 1,
    },
    {
      source: "node1",
      target: "node2",
      value: 1,
    },
    {
      source: "node2",
      target: "node3",
      value: 1,
    },
    {
      source: "node1",
      target: "vf_uv_045_5",
      value: 1,
    },
  ],
};

export class Graph {
  static isGraph(graph) {
    return graph instanceof Graph;
  }

  // -------- tested functions --------

  //tested
  static isNode(node) {
    return Node.isNodeLike(node);
  }

  //tested
  static isLink(link) {
    return Link.isLinkLike(link);
  }

  //tested
  nodeExists(node_id) {
    return this.nodesById.hasOwnProperty(node_id);
  }

  //tested
  static nodesEqual(node1, node2) {
    return Node.nodesEqual(node1, node2);
  }

  //tested
  static linksEqual(link1, link2) {
    return Link.linksEqual(link1, link2);
  }

  //tested
  static memberOfNodeSelection(node, selection) {
    if (!Array.isArray(selection))
      throw new Error("array expected for selection");
    for (const sel_node of selection) {
      if (Graph.nodesEqual(node, sel_node)) {
        return true;
      }
    }
    return false;
  }

  //tested
  static memberOfLinkSelection(link, selection) {
    if (!Array.isArray(selection)){
      throw new Error("array expected for selection");
    }
    for (const sel_link of selection) {
      if (Graph.linksEqual(link, sel_link)) {
        return true;
      }
    }
    return false;
  }

  //tested
  getNodeSelectionFromIds(nodeIDs) {
    let returnNodes = [];
    for (const nodeId of nodeIDs) {
      if (this.nodeExists(nodeId)){
        let node = this.nodesById[nodeId];
        returnNodes.push(Node.getNodeData(node))
      }
    }
    return returnNodes;
  }

  //tested
  getNeighborhoodNodeIDs(node_id_or_list_of) {
    if (isString(node_id_or_list_of)) {
      return this.getNeighborhoodNodeIDsForNode(node_id_or_list_of);
    }
    if (isList(node_id_or_list_of)) {
      return this.getNeighborhoodNodeIDsForNodes(node_id_or_list_of);
    }
    throw new Error(
      "Expected getNeighborhoodNodeIDs to be called with string representing node_id of list of strings representing node_ids"
    );
  }

  //tested
  getNeighborhoodNodeIDsForNode(node_id) {
    if (!this.nodeExists(node_id)) return [];
    let nodeIDs = Set();
    nodeIDs = nodeIDs.add(node_id);
    for (const link of this.referenceLinks) {
      if(link.isLinked(node_id)){
        nodeIDs = nodeIDs.add(link.getLinkPartner(node_id));
      }
    }
    return nodeIDs.toArray();
  }

  //tested
  getNeighborhoodNodeIDsForNodes(node_ids) {
    let nodeIDs = Set();
    for (const node_id of node_ids) {
      let curNodeNeighbors = this.getNeighborhoodNodeIDsForNode(node_id);
      for (const cur_node_id of curNodeNeighbors) {
        nodeIDs = nodeIDs.add(cur_node_id);
      }
    }
    return nodeIDs.toArray();
  }

  //tested
  getNeighborhoodGraph(node_id_or_list_of) {
    if (isString(node_id_or_list_of) || isList(node_id_or_list_of)){
      let neighborIDs = this.getNeighborhoodNodeIDs(node_id_or_list_of);
      return this.constructGraphSelectionForNodeIDs(neighborIDs);
    }
    throw new Error(
      "Expected getNeighborhoodGraph to be called with string representing node_id of list of strings representing node_ids"
    );
  }

  //tested implicitly by testing getNeighborhoodGraph
  constructGraphSelectionForNodeIDs(node_ids) {
    let returnLinks = this.getLinksForNodeIDSelection(node_ids);
    let returnNodes = this.getNodeSelectionFromIds(node_ids);
    let returnGraph = { nodes: returnNodes, links: returnLinks };
    return returnGraph;
  }

  //tested
  // return links as referenced in reference_links that have source and target in nodeIDs
  getLinksForNodeIDSelection(nodeIDs) {
    let returnLinks = [];
    for (const link of this.referenceLinks){
      if (link.hasPairIn(nodeIDs)){
        returnLinks.push(link.getLinkCopy());
      }
    }
    return returnLinks;
  }

  // ^^^^^^^^ tested functions ^^^^^^^^

  constructor(nodes, links, rootNodeIds = []) {
    this.referenceNodes = Graph.createReferenceListFromNodes(nodes);
    this.referenceLinks = Graph.createReferenceListFromLinks(links);
    this.rootNodeIds = rootNodeIds;
    this.nodesById = Object.fromEntries(
      this.referenceNodes.map((node) => {
        let nCopy = node.getNodeCopy();
        return [nCopy.id, nCopy];
      })
    );
    this.linksById = Object.fromEntries(
      this.referenceLinks.map((link) => {
        let lCopy = link.getLinkCopy();
        return [link.getId(), lCopy];
      })
    );
  }

  linkExists(maybe_link){
    return Link.oneOfLinks(maybe_link, this.referenceLinks);
  }

  getNodeIDsMatchingSearch(searchString){
    //remove duplicate whitespaces
    searchString = searchString.replace(/\s+/g, ' ');
    let searchList = searchString.split(" ");
    let result_ids = [];
    for (const node of this.referenceNodes){
      if(Node.nodeMatchesSearch(node, searchList)){
        result_ids.push(node.id);
      }
    }
    return result_ids;
  }

  addSearchNode(searchString){
    let foundIDs = this.getNodeIDsMatchingSearch(searchString);
    let display_name = `Search Term: ${searchString}`;
    this.addNewNode(display_name, [
      {
        attributeName: "Description",
        attributeContent: `This search links to each variable and context that matches with the provided search term \n\t${searchString}`,
        visibility: "visible"
      }
    ], "visible", "context", display_name);

    for (const id of foundIDs){
      this.addNewLink(display_name, id);
    }
    return this;
  }

  addNewLink(source, target){
    if(Link.oneOfLinks(
      {source: source, target: target},
      this.referenceLinks
    )){
      return this;
    }
    this.referenceLinks.push(
      new Link(this.referenceLinks.length.toString(), source, target)
    );
    return this;
  }

  addNewNode(node_id, node_details, visibility, type, display_name){
    if(!this.nodeExists(node_id)){
      let node = new Node(
          node_id, 
          node_details,
          type,
          visibility,
          display_name
      );
      this.referenceNodes.push(node.getNodeCopy());
      this.nodesById[node_id] = node.getNodeCopy();
    }
    else {
      let existing_node = this.getNodeForId(node_id);
      let details = Node.mergeDetails(existing_node.details, node_details);
      let node = new Node(
        node_id,
        details,
        type,
        visibility,
        display_name
      )
      let new_reference_nodes = this.referenceNodes.filter(
        (current_node) => {
          return current_node.id !== node_id;
        }
      );
      new_reference_nodes.push(node);
      this.referenceNodes = new_reference_nodes;
      this.nodesById[node_id] = node.getNodeCopy();
    }
    return this;
  }
  
  getNodeForId(id){
    if(this.nodeExists(id)){
      return Node.getNodeData(this.nodesById[id]);
    }
    throw new Error(`Node with id ${id} does not exist`);
  }

  getNodes() {
    let nodes = [];
    for (const refNode of this.referenceNodes) {
      nodes.push(refNode.getNodeCopy());
    }
    return nodes;
  }

  getLinks() {
    let links = [];
    for (const refLink of this.referenceLinks) {
      links.push(refLink.getLinkCopy());
    }
    return links;
  }

  getLinkedFromNodes(id){
    let nodes = [];
    for (const refLink of this.referenceLinks){
      if (refLink.isLinkedTo(id)){
        nodes.push(Node.getNodeData(this.nodesById[refLink.source]));
      }
    }
    return nodes;
  }

  getLinkedToNodes(id){
    let nodes = [];
    for (const refLink of this.referenceLinks){
      if (refLink.isLinkedFrom(id)){
        nodes.push(Node.getNodeData(this.nodesById[refLink.target]));
      }
    }
    return nodes;
  }

  //tested
  static createReferenceListFromLinks(links) {
    let linkList = [];
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      if (!Link.isLinkLike(link)){
        throw new Error(`Graph.createReferenceListFromLinks(links) expects list of linkLike objects. Link given is
        \t ${JSON.stringify(link)}`);
      }
      linkList.push(new Link(i.toString(), link.source, link.target));
    }
    return linkList;
  }

  static createReferenceListFromNodes(nodes) {
    let nodeList = [];
    for (const node of nodes) {
      if (!Node.isNodeLike(node)) {
        throw new Error(
          "createReferenceListFromNodes expects list of nodelikes. Node given is " +
            JSON.stringify(node)
        );
      }
      nodeList.push(new Node(node.id, node.details, node.type, node.visibility, node.display_name));
    }
    return nodeList;
  }

  getRootGraph() {
    if (Array.isArray(this.rootNodeIds) && this.rootNodeIds.length == 0) {
      return this.getFullGraph();
    }
    return this.getNeighborhoodGraph(this.rootNodeIds);
  }

  getFullGraph() {
    return { nodes: this.getNodes(), links: this.getLinks() };
  }
}
