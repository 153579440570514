import { List } from "immutable";
import { isList, isString } from "../../nonComponentFunctions/helper_functions";
import { RedDividerBar } from "../divider and frames/divider_bars";
import { HeaderSmall, Indent, Label } from "../text_display/main_content_text_styling";
import { TestCollapsible } from "../widgets/collapsible";
import styled from "styled-components";
import { OverflowContainerVertical } from "../divider and frames/layouts";
import { Node } from "../../nonComponentFunctions/node";
import { OuterPanel } from "./panel_styling";
import { useCurrentVariables, useGraph, useProposalSelectionMapping } from "./contexts";
import { useEffect, useState } from "react";

const ScrollingListContainer = styled.div`
  ${OverflowContainerVertical}
  padding: 15px;
  border-radius: 10px;
  padding-bottom: 20px;
`

const CheckboxLine = styled.span`
  float: right;
  margin-right: 30px;
  position: relative;
`

const CheckBox = styled.input`
  transform: scale(2);
  margin-left: 10px;
  position: relative;
`

const Link = styled.a`
  color: blue;
  font-size: 1rem;
`

function Detail({attributeContent, attributeName, visibility}){
  let content = attributeContent;
  if (isString(content) &&
    content.includes("[[") &&
    content.includes("]]")){
    content = content.replace("[[", "");
    content = content.replace("]]", "");
    content = <Link>{content}</Link>;
  }
  if(visibility === "intern" || visibility === "internal"){
    return <></>;
  }
  if(visibility === "public" || visibility === "visible"){
    return (
      <TestCollapsible attributeName={attributeName}>
        {content}
      </TestCollapsible>
    )
  }
}

function DetailsRenderer({details, variable_id}){
  let filteredDetails = Node.filterEmptyDetails(details);
  let detailComponents = filteredDetails.map((elem, index) => {
    return (
      <Detail 
        key={index} 
        attributeName={elem.attributeName}
        attributeContent={elem.attributeContent}
        visibility={elem.visibility}
      />
    )})

  if (isList(details) && details.length === 0) return <></>;
  return (
    <ScrollingListContainer>
      <Detail 
        key={variable_id}
        attributeName={"Variable name"}
        attributeContent={variable_id}
        visibility={"public"}
      />
      {detailComponents}
    </ScrollingListContainer>
  )
}

function VariableDetailAndCheckboxLine({association, variable_id, selected}){
  const {selectionManager, setSelectionManager} = useProposalSelectionMapping();
  const handleCheckboxClick = () => {
    let newManager = selectionManager.setSelection(association.proposal_id, variable_id, association.kind, !selected);
    setSelectionManager(newManager);
  }
  
  return (
    <Indent margin_left={"24px"}>
      <Label>Details</Label>
      <CheckboxLine>
        Select Variable
        <CheckBox 
          type={"checkbox"}
          checked={selected}
          onChange={()=>{handleCheckboxClick()}} />
      </CheckboxLine>
    </Indent>
  )
}

function VariableRenderer({nodeLike, association}){
  const displayName = Node.getDisplayName(nodeLike);
  const {id, details } = nodeLike;
  const [selected, setSelected] = useState(false);
  const {selectionManager, forceUpdateClock} = useProposalSelectionMapping();

  const setSelection = () => {
    const selectedInManager = selectionManager.variableSelectedAsKindForProposal(id, association.kind, association.proposal_id);
    setSelected(selectedInManager);
  }
  useEffect(()=>{
    setSelection();
  },[id]);
  useEffect(()=>{setSelection();},[forceUpdateClock]);
  return <>
      <HeaderSmall text={displayName} />
      <VariableDetailAndCheckboxLine 
        association={association}
        variable_id={id} 
        selected={selected}/>
      <RedDividerBar width={"95%"} />
      <DetailsRenderer details={details} variable_id={id}/>
    </>
}

function VMContextRenderer({nodeLike}){
  return <>
      <HeaderSmall text={Node.getDisplayName(nodeLike)} />
      <Indent margin_left={"24px"}>
        ToDo context explaination
      </Indent>
      <RedDividerBar width={"95%"} />
      <DetailsRenderer 
        details={nodeLike.details}
        variable_id={nodeLike.id} />
    </>
}

export function DetailsPanel({association}){
  const {forceUpdateClock, variablesManager, setVariablesManager} = useCurrentVariables();
  const {getNodeForId} = useGraph();
  const [rendered, setRendered] = useState(<></>);
  const [templateRows, setTemplateRows] = useState("");

  const displayCurrentNode = () => {
    const maybe_id = variablesManager.getCurrentVariableForProposalAndKind(
      association.proposal_id,
      association.kind
    );
    
    if(maybe_id){
      const node = getNodeForId(maybe_id);
      const nodeLike = Node.getNodeData(node);
      if(Node.isVMContext(nodeLike)){
        setTemplateRows("auto auto auto 1fr");
        setRendered(<VMContextRenderer nodeLike={nodeLike} />);
        return;
      }
      if(Node.isVariable(nodeLike, "VM_Fragebogen_Benjamin")){
        setTemplateRows("auto auto auto 1fr");
        setRendered(<VariableRenderer association={association} nodeLike={nodeLike} />);
        return;
      }
    }
    else {
      setRendered(<></>);
      setTemplateRows("");
    }

  }

  useEffect(()=>{
    displayCurrentNode();
  }, [forceUpdateClock])
  useEffect(()=>{
    displayCurrentNode();
  }, [])

  
  return (
    <OuterPanel
      grid_column={"1 / span 2"}
      template_rows={templateRows}
      rowGap={"6px"}>
      {rendered}
    </OuterPanel>
  )
}
