import { useState } from "react";
import { RowGrid } from "../divider and frames/layouts";
import { HeaderTiny } from "../text_display/main_content_text_styling";
import { CircleLoader } from "../widgets/loader";
import { Modal } from "./modal";
import { useEffect } from "react";
import { isFunction } from "../../nonComponentFunctions/helper_functions";


export function MainInfoModal({modal_input}){
  const [status, setStatus] = useState("loading");
  const [processingStarted, setProcessingStarted] = useState(false);
  const [statusText, setStatusText] = useState("Loading");
  const [message, setMessage] = useState("");

  const modalStatusCallback = (status) => {
    setStatus(status);
  }

  useEffect(()=>{
    if(!processingStarted){
      if(!isFunction(modal_input.processModalCallback)){
        setStatus('error');
      }
      else{
        modal_input.processModalCallback(
          modalStatusCallback,
          setStatusText, 
          setMessage) && setProcessingStarted(true);
      }
    }
  }, [modal_input])

  return (
    <Modal closeModalCallback={()=>{modal_input.closeModalCallback()}}>
      <RowGrid>
        {
          ((status === 'loading') &&
            <>
              <HeaderTiny text={statusText || 'Loading'}/>
              <CircleLoader/>
            </>) ||
          ((status === 'error') &&
            <>
              <HeaderTiny text={statusText || 'Error'}/>
              {message}
            </>) ||
          ((status === 'success') &&
            <>
              <HeaderTiny text={statusText || 'Success'}/>
              {message}
            </>) ||
          <>
            <HeaderTiny text={"Unknown status"}/>
          </>

        }
      </RowGrid>
    </Modal>
  )
}
