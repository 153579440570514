import styled from "styled-components";
import {ThemeColor} from "./colors";

export const Bold = styled.span`
  font-weight: bold;
  position: relative;
`;

export const UnderlinedBold = styled(Bold)`
  &:before {
    content: "";
    position: absolute;
    transform: skew(-12deg) translateX(-50%);
    width: 33px;
    left: 10px;
    height: 5px;
    background: ${ThemeColor.lightred};
    top: 13px;
  }
`

export const Oblique = styled.span`
  font-style: oblique;
  position: relative;
`