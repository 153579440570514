import {
  isString,
  isPrimitive,
  isList,
  structuredClone,
  deepEqual,
  isNonNullObject
} from "./helper_functions";


export class Link {
  constructor(link_id, link_source, link_target) {
    this.linkId = Link.validateLinkId(link_id);
    this.source = Link.validateLinkSource(link_source);
    this.target = Link.validateLinkTarget(link_target);
  }

  getLinkCopy() {
    return structuredClone({ source: this.source, target: this.target });
  }

  static oneOfLinks(maybe_link, links) {
    for (const link of links) {
      if (Link.linksEqual(maybe_link, link))
        return true;
    }
    return false;
  }

  getId(){
    return this.linkId;
  }

  static getLinkData(maybe_link) {
    if (Link.isLink(maybe_link))
      return maybe_link.getLinkCopy();
    if (Link.isLinkLike(maybe_link))
      return maybe_link;

    throw new Error(`For Link.getLinkData(maybe_link)
    \t expect maybe_link to be instance of Link or to evaluate to true using Link.isLinkLike(maybe_link)
    \t maybe_link actual = ${JSON.stringify(maybe_link)}`);
  }

  isLinked(source_or_target_or_list_of) {
    if (isString(source_or_target_or_list_of)) {
      return (
        this.source === source_or_target_or_list_of ||
        this.target === source_or_target_or_list_of
      );
    }
    if (isList(source_or_target_or_list_of)) {
      for (const source_or_target of source_or_target_or_list_of) {
        if (this.isLinked(source_or_target))
          return true;
      }
    }
    return false;
  }

  isLinkedTo(id){
    return this.target === id;
  }

  isLinkedFrom(id){
    return this.source === id;
  }

  hasPairIn(sources_targets) {
    return (
      isList(sources_targets) &&
      sources_targets.includes(this.source) &&
      sources_targets.includes(this.target)
    );
  }

  getLinkPartner(source_or_target) {
    if (this.source === source_or_target)
      return this.target;
    if (this.target === source_or_target)
      return this.source;

    throw new Error(`For instanceof Link link - link.getLinkPartner(source_or_target) -> source_or_target is expected to be link.source or link.target
    \t link.source = ${this.source}
    \t link.target = ${this.target}`);
  }

  static isLink(link) {
    return (link instanceof Link);
  }

  static isLinkLike(link) {
    return (
      isNonNullObject(link) &&
      link.hasOwnProperty("source") &&
      link.hasOwnProperty("target") &&
      isPrimitive(link.source) &&
      isPrimitive(link.target)
    );
  }

  static validateLinkId(id) {
    if (!isString(id)) {
      throw new Error(`For Link.validateLinkId:: id is expected to be string - actual type of id = ${typeof id}`);
    }
    return id;
  }

  static validateLinkSource(source) {
    if (!isString(source)) {
      throw new Error(`For Link.validateLinkSource:: source is expected to be string - actual type of source = ${typeof id}`);
    }
    return source;
  }

  static validateLinkTarget(target) {
    if (!isString(target)) {
      throw new Error(`For Link.validateLinkTarget:: target is expected to be string - actual type of target = ${typeof target}`);
    }
    return target;
  }

  static linksEqual(link1, link2) {
    if (!Link.isLink(link1) && !Link.isLinkLike(link1))
      return false;
    if (!Link.isLink(link2) && !Link.isLinkLike(link2))
      return false;
    return deepEqual(Link.getLinkData(link1), Link.getLinkData(link2));
  }

}
