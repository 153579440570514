export function arrayUnion(arr1, arr2) {
  let concat = arr1.concat(arr2);
  return concat.filter((val, pos) => {
    return concat.indexOf(val) === pos;
  });
}

/*
    function taken from Stackoverflow
    date-accessed: 2023-03-21
    author: user229044
    url: https://stackoverflow.com/questions/957537/how-can-i-display-a-javascript-object

    The function is used to compare Javascript Objects
    I have NOT checked the deepEqual function for correctness directly
    The function is only to be used by nodesEqual and linksEqual
    Those two functions are explicitly tested and thus save to use
    
    Do only use deepEqual if you test the behaviour for your defined use cases
    */
export function deepEqual(x, y) {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
}

export function structuredClone(obj) {
  let copy;
  if (isPrimitive(obj)) return obj;

  if (obj instanceof Date) return cloneDate(obj);

  if (isList(obj)) return cloneList(obj);

  if (isNonNullObject) return cloneObject(obj);

  throw new Error(`Unexpected argument to structuredClone(obj) - obj object is neither 
  \t primitive (any non object including null), 
  \t Array,
  \t Date or
  \t non null Object
  obj given is ${obj} with typeof obj = ${typeof obj}`);
}

export function cloneObject(obj) {
  let copy = {};
  for (const attr in obj) {
    if (obj.hasOwnProperty(attr)) {
      copy[attr] = structuredClone(obj[attr]);
    }
  }
  return copy;
}

//tested
export function cloneList(list) {
  let copy = [];
  for (let i = 0, len = list.length; i < len; i++) {
    copy[i] = structuredClone(list[i]);
  }
  return copy;
}

export function isOneOf(blob, candidates) {
  for (const candidate of candidates){
    if (isNonNullObject(candidate) && isNonNullObject(blob) && deepEqual(blob)) return true;
    if (blob === candidate) return true;
  }
  return false;
}

export function cloneDate(date) {
  let copy = new Date();
  copy.setTime(date.getTime());
  return copy;
}

//tested
export function isPrimitive(maybe_primitive) {
  return !isNonNullObject(maybe_primitive);
}

//tested
export function isNonNullObject(maybe_obj) {
  return maybe_obj !== null && typeof maybe_obj === "object";
}

//tested
export function isString(maybe_string) {
  return Object.prototype.toString.call(maybe_string) === "[object String]";
}

//tested
export function isList(maybe_list) {
  return Array.isArray(maybe_list);
}

export function isFunction(maybe_function){
  return (typeof maybe_function === "function");
}

export function hasNumberOfUnsetArguments(number_of_arguments, func){
  if (!isFunction(func)) throw new TypeError(`For hasNumberOfUnsetArguments :: expect func to be a function
  func serializes to 
  \t ${JSON.stringify(func)}`);
  return (number_of_arguments === getNumberOfUnsetArguments(func));
}

export function getNumberOfUnsetArguments(func){
  if (!isFunction(func)) throw new TypeError(`For getNumberOfUnsetArguments :: expect func to be a function
  func serializes to 
  \t ${JSON.stringify(func)}`);
  return func.length;
}

// does not work if array elements are equal, but not identical objects
export function simpleArrayEqual(arr1, arr2) {
  if (arr1 === arr2) return true;
  if (arr1 == null || arr2 == null) return false;
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}
