import styled from "styled-components";
import { LabelSmall, MainContentElementContainer, OptionalInputIndent } from "../text_display/main_content_text_styling";
import { useEffect, useState } from "react";
import { isFunction, isNonNullObject } from "../../nonComponentFunctions/helper_functions";
import { BorderSolidRed, BorderSolidSlimBlack } from "../themes and styles/borders";
import { Submit } from "../buttons/menu_area_buttons";

export const InputItem = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;

    && > * {
        ${props => (props.invalid && BorderSolidRed) || BorderSolidSlimBlack}
        padding: 5px;
        margin: 4px;
        min-height: 22px;
    }
`

const InputPositioningSideBySide = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 8px;
    padding-bottom: 4px;
`

const InputPositioningTopDown = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const AlignChildRightWithMaxWidth = styled.div`
    && > * {
      max-width: 18rem;
      margin-left: auto;
    }
`

export const TextArea = styled.textarea`
    resize: vertical;
    min-height: 75px;
`

export const StyledCheckbox = styled.input`
    margin-left: 5px;
`
export function OptionalTextArea({placeholder, onChangeCallback}){
  return (
    <OptionalInputIndent>
      <InputItem>
        <TextArea placeholder={placeholder} onChange={onChangeCallback}/>
      </InputItem>
    </OptionalInputIndent>
  )
}

export function InputElementCheckbox(props){
    return (
        <span>
            <StyledCheckbox type={props.type} checked={props.checked} onChange={props.onChange}/>
            <LabelSmall>{props.label}</LabelSmall>
        </span>
    )
}

export function InputElementButtonContainer({children}){
  return (
    <MainContentElementContainer >
      <AlignChildRightWithMaxWidth>
        {children}
      </AlignChildRightWithMaxWidth>
    </MainContentElementContainer>
  )
}

export function InputElementContainerSideBySidePositioning(props){
    return (
        <MainContentElementContainer label={props.label} {...props}>
            <InputPositioningSideBySide>
                {props.children}
            </InputPositioningSideBySide>
        </MainContentElementContainer>
    )
}

export function InputElementContainerTopDownPositioning(props){
    return (
        <MainContentElementContainer label={props.label} {...props}>
            <InputPositioningTopDown>
                {props.children}
            </InputPositioningTopDown>
        </MainContentElementContainer>
    )
}

export function InputElementSingleChoiceRadio({label_array, box_selected_callback}){
    const [activeBoxId, updateActiveBoxId] = useState(false);
    const [boxes, updateBoxes] = useState(getNewStateOfBoxes())
    useEffect(()=>{
      updateBoxes(getNewStateOfBoxes());
    }, [activeBoxId])

    function getNewStateOfBoxes(){
        return (
            label_array.map((label, index)=>{
                return (
                    <InputElementCheckbox
                        key={index}
                        index={label}
                        checked={(index === activeBoxId)} 
                        label={label}
                        type={"radio"}
                        onChange={(event)=>{
                          updateActiveBoxId(index);
                          if(box_selected_callback !== undefined){
                            box_selected_callback(label);
                          }
                        }}/>
                )
            })
        )
    }

    return <InputItem>{boxes}</InputItem>;
}

export function InputElementMultilineTextArea(props){
    return (
        <InputItem>
            <TextArea onChange={props.onChange}/>
        </InputItem>
    )
}

export function InputItemMail({mailCallback}){
  const [invalidMail, setInvalidMail] = useState(false);

  const onMailChange = (value) => {
    var re = /\S+@\S+\.\S+/;
    let invalid = !re.test(value);
    setInvalidMail(invalid);
    mailCallback(value);
  }

  return <InputItem invalid={invalidMail}>
    <input 
      onChange={(event)=> {onMailChange(event.target.value)}}
      type="text"
      placeholder="Mail"/>
  </InputItem>
}
