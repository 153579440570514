import { createContext, useContext, useState } from "react";
import styled from "styled-components";
import { UncollapseMenuArea } from "./buttons/menu_area_buttons";
import { MainInfoModal } from "./modals/main_info_modal";
import { ModalInput } from "./modals/modal";
import { useEffect } from "react";
import { OverlayContentModal } from "./modals/overlay_content_modal";

const PageLayoutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
`


const MainContent = styled.div`
    background-color: rgba(255, 255, 255, 1);
    overflow: auto;
    border-radius: 3px;
    flex-grow: 2;
    position: relative;
    height: calc(100% - 4rem);
    margin: 1%;
    padding: 1rem;
`;

const MenuContent = styled.div`
    position: relative;
    margin: 1%;
    display: flex;
    justify-content: flex-end;
`;

export const PageElementContainer = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: space-around;
    align-items: center;
    position: relative;
`;

const MenuAreaCollapsed = styled.div`
    position: absolute;
    top: 20px;
    right: 0px;
    margin-right: 4%;
    height: 30px;
    width: 30px;
    
`
const LayoutContext = createContext();

export const usePageLayout = () => { return useContext(LayoutContext)}

export function PageLayoutNavigationAreaRight(props){

    const [menuCollapsedFlag, updateMenuCollapsedFlag] = useState(false);
    const [showModalFlag, setShowModalFlag] = useState(true);
    const [modalInput, setModalInput] = useState(null);
    const [overlayModalContent, setOverlayModalContent] = useState(null);
    const [showOverlayModal, setShowOverlayModal] = useState(false);

    const [menuArea, updateMenuArea] = useState(
        <MenuContent>{props.menu}</MenuContent>
    );

    const activateModal = (modal_close_func, modal_processing_func) =>{
      const close_callback = ()=>{
        setShowModalFlag(false);
        setModalInput(null);
        modal_close_func();
      };

      setModalInput(new ModalInput(close_callback, modal_processing_func))
      setShowModalFlag(true);
    }

    const activateOverlayModal = (modal_content) => {
      setOverlayModalContent(modal_content);
      setShowOverlayModal(true);  
    }
    
    const closeOverlayModal = () => {
      setShowOverlayModal(false);
    }

    const toggleMenuCollapse = () => {
        if (!menuCollapsedFlag) {
            updateMenuArea(
            <MenuAreaCollapsed>
                <UncollapseMenuArea/>
            </MenuAreaCollapsed>)
        }
        else{
            updateMenuArea(
                <MenuContent>
                    {props.menu}
                </MenuContent>
            )
        }
        updateMenuCollapsedFlag(!menuCollapsedFlag);
    }

    const value = {
      activateOverlayModal: activateOverlayModal,
      closeOverlayModal: closeOverlayModal,
      toggleMenuCollapse: toggleMenuCollapse,
      activateModal: activateModal
    }
    
    return (
        <LayoutContext.Provider value={value}>
          {showModalFlag && modalInput !== null && <MainInfoModal modal_input={modalInput}/>}
          {showOverlayModal && overlayModalContent !== null && <OverlayContentModal content={overlayModalContent} closeModalCallback={()=>{closeOverlayModal()}}/>}
            <PageElementContainer>
                <MainContent>{props.content}</MainContent>
                {menuArea}
            </PageElementContainer>
        </LayoutContext.Provider>
    )
}
