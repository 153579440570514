import styled from "styled-components";
import Collapsible from "react-collapsible";
import { useState } from "react";
import { ThemeColor } from "../themes and styles/colors";

export const CollapseContent = styled.div`
  padding: 10px 15px 15px;
  border: none;
  font-size: 15px;
  color: #615f5f;
  line-height: 27px;
`;


const CollapseHeader = styled.div`
  display: block;
  padding: 12px 15px;
  background: #fff;
  font-size: 18px;
  font-weight: bold;
  color: ${ThemeColor.linkpurple};
  border: 1px solid #ececec;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: all 0.3s ease-in 0s;

  &:before {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    width: 25px;
    height: 25px;
    line-height: 28px;
    font-size: 15px;
    font-weight: 900;
    color: ${ThemeColor.linkpurple};
    text-align: center;
    position: absolute;
    top: 8px;
    right: 15px;
    transform: rotate(135deg);
    transition: all 0.3s ease-in 0s;
  }
`;

export const TestCollapsible = (props) => {
  const [open, setOpen] = useState(false);
  const handleTriggerClick = () => {
    setOpen(!open);
  };
  return (
    <Collapsible
      transitionTime={400}
      easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
      trigger={<CollapseHeader>{props.attributeName}</CollapseHeader>}
      {...{ open, handleTriggerClick }}
    >
      <CollapseContent>{props.children}</CollapseContent>
    </Collapsible>
  );
};
