import { fetch_json_endpoint, request_success } from "./RequestHelpers";
import { structuredClone } from "./helper_functions";

const URL_SET_VARIABLES_FOR_PROPOSAL = "https://api.hchs.hamburg/api_endpoints/proposals/set_proposal_variables.php";

export class CurrentVariableManager{
  constructor() {
    this.currentVariables = {};
    this.lastTouchedElements = {
      proposal_id: "",
      kind: "",
      variable_id: ""
    }
  }

  containsProposal(proposal_id){
    return this.currentVariables.hasOwnProperty(proposal_id);
  }

  containsKindForProposal(proposal_id, kind){
    return (
      this.containsProposal(proposal_id) &&
      this.currentVariables[proposal_id].hasOwnProperty(kind)
    );
  }

  currentVariableSetForProposalAndKind(proposal_id, kind){
    return (
      this.containsKindForProposal(proposal_id, kind) &&
      this.currentVariables[proposal_id][kind].hasOwnProperty('current')
    );
  }

  getCurrentVariableForProposalAndKind(proposal_id, kind){
    return (
      this.currentVariableSetForProposalAndKind(proposal_id, kind) &&
      this.currentVariables[proposal_id][kind]['current']
    );
  }

  getLastTouchedElements(){
    return this.lastTouchedElements;
  }

  setCurrentVariable(proposal_id, kind, variable_id){  
    if (!this.containsProposal(proposal_id)){
      this.currentVariables[proposal_id] = {};
    }
    if (!this.containsKindForProposal(proposal_id, kind)){
      this.currentVariables[proposal_id][kind] = {};
    }
    if (!this.currentVariableSetForProposalAndKind(proposal_id, kind)){
      this.currentVariables[proposal_id][kind]['current'] = null;
    }
    this.currentVariables[proposal_id][kind]['current'] = variable_id;
    this.lastTouchedElements = {proposal_id: proposal_id, kind: kind, variable_id: variable_id};
    return this;
  }
}

export class VMHistoryManager{
  constructor() {
    this.histories = {};
  }

  containsHistoryForProposal(proposal_id){
    return this.histories.hasOwnProperty(proposal_id);
  }

  containsHistoryForVariableKindForProposal(proposal_id, kind){
    return ( this.containsHistoryForProposal(proposal_id) && 
      this.histories[proposal_id].hasOwnProperty(kind)
    );
  }

  ensureExistingEntry(proposal_id, kind){
    if(!this.containsHistoryForProposal(proposal_id)) this.histories[proposal_id] = {};
    if(!this.containsHistoryForVariableKindForProposal(proposal_id, kind)) this.histories[proposal_id][kind] = [];
  }

  addToHistory(proposal_id, kind, variable_id){
    this.ensureExistingEntry(proposal_id, kind);
    this.histories[proposal_id][kind].push(variable_id);
    return this;
  }

  getLastHistoryElements(proposal_id, kind, numberOfElements){
    if(!this.containsHistoryForVariableKindForProposal(proposal_id, kind)) return [];
    return this.histories[proposal_id][kind].slice(-numberOfElements);
  }

  revertToLastIdInHistory(proposal_id, kind, variable_id){
    let history_copy = structuredClone(this.histories[proposal_id][kind]);
    let last_element = null;
    while (history_copy.length !== 0 && last_element !== variable_id){
      last_element = history_copy.pop();
    }
    if (last_element === variable_id){
      history_copy.push(last_element);
      this.histories[proposal_id][kind] = history_copy;
      return {revertStatus: true, manager: this};
    }
    return {revertStatus: false, manager: this};
    
  }
}

export class VariableSelectionManager{
  constructor() {
    this.selections = {};
  }

  containsProposal(proposal_id){
    return this.selections.hasOwnProperty(proposal_id);
  }

  containsVariableForProposal(proposal_id, variable_id){
    return (
      this.containsProposal(proposal_id) && 
      this.selections[proposal_id].hasOwnProperty(variable_id)
    );
  }

  variableHasStatusForKindInProposal(proposal_id, variable_id, kind){
    return (
      this.containsVariableForProposal(proposal_id, variable_id) &&
      this.selections[proposal_id][variable_id].hasOwnProperty(kind)
    );
  }

  variableSelectedAsKindForProposal(variable_id, kind, proposal_id){
    return(
      this.variableHasStatusForKindInProposal(proposal_id, variable_id, kind) &&
      this.selections[proposal_id][variable_id][kind]
    );
  }

  setSelection(proposal_id, variable_id, kind, selection_status){
    if(!this.containsProposal(proposal_id)){
      this.selections[proposal_id] = {};
    }
    if(!this.containsVariableForProposal(proposal_id, variable_id)){
      this.selections[proposal_id][variable_id] = {};
    }
    if(!this.variableHasStatusForKindInProposal(proposal_id, variable_id, kind)){
      this.selections[proposal_id][variable_id][kind] = {};
    } 
    this.selections[proposal_id][variable_id][kind] = selection_status;
    return this;
  }

  setSelections(selections){
    this.selections = selections;
  }
  
  getSelectionsForProposalAndKind(proposal_id, kind){
    if(!this.containsProposal(proposal_id)){
      return [];
    }
    let result = [];
    for (const variable_id of Object.keys(this.selections[proposal_id])){
      if(this.variableSelectedAsKindForProposal(variable_id, kind, proposal_id)){
        result.push(variable_id);
      }
    }
    return result;
  }

  getSelections(){
    return this.selections;
  }

  async db_set_variables_for_proposal(requestor_mail, auth_token, target_proposal_id, internal_proposal_id){
    const request_body = {
      mail: requestor_mail,
      jwt: auth_token,
      proposal_id: target_proposal_id,
      variables: {
        exposure: this.getSelectionsForProposalAndKind(internal_proposal_id, "exposure"),
        confounder: this.getSelectionsForProposalAndKind(internal_proposal_id, "confounder"),
        outcome: this.getSelectionsForProposalAndKind(internal_proposal_id, "outcome")
      }
    }
    const response = await fetch_json_endpoint(URL_SET_VARIABLES_FOR_PROPOSAL, request_body);
    if(request_success(response)){
      return true;
    }
    throw new Error(`some error occured while processing request. Response id ${JSON.stringify(response)}`);
  }
}
