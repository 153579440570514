import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {UKE_HCHS_LOGO} from "./uke_hchs_logo_definition.js";
import { isList, isNonNullObject, isString } from "../helper_functions.js";
import { TestProposal } from "../test_proposal.js";
import { Option, Text } from "../proposal_structure/structuring_elements.js";
import { Proposal } from "../Proposal.js";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const only_left_margin_value = [15, 0, 0, 0];
const buffer_line = {
  text: "\n",
};

class StructureElementRenderer {
  constructor(proposal){
    this.proposal = proposal;
  }

  renderStructureElement(structure_element){
    if (!isNonNullObject(structure_element) || !structure_element.hasOwnProperty('type')) return null;
    switch(structure_element['type']){
      case 'single_choice': 
        return this.renderSingleChoice(structure_element);
      case 'option':
        return this.renderOption(structure_element);
      case 'text':
        return this.renderText(structure_element);
      case 'collection':
        return this.renderCollection(structure_element);
      case 'multi_choice':
        return this.renderMultiChoice(structure_element);
      case 'grouping':
        return this.renderNamelessGrouping(structure_element);
      case 'subcollection':
        return this.renderSubcollection(structure_element);
      default:
        return null;
    }
  }

  isText(text){
    return (
      isNonNullObject(text) &&
        text.hasOwnProperty('name') &&
        text.hasOwnProperty('type') &&
        text instanceof Text &&
        text.type === 'text' 
    )
  }

  getRenderTextValue(text){
    if(!this.isText(text) || !isNonNullObject(this.proposal)) return "Text could not be obtained from proposal";
    let text_value = text.getContent(this.proposal);
    if(text_value === "") return "n/a";
    return text_value;
  }

  renderText(text){
    const text_value = this.getRenderTextValue(text);
    return {
      stack: [
        this.getNameElement(text.name),
        {
          text: text_value,
          fontSize: 13,
          alignment: 'justify',
          margin: only_left_margin_value,
          lineHeight: 1
        },
      ],
    }
  }

  renderCollection(collection){
    const collection_elements = this.mapStructureElements(collection.elements);

    return {
      stack: [
        this.getNameElement(collection.name, 19),
        {
          stack: collection_elements,
          margin: only_left_margin_value
        },
        { canvas: [ { type: 'rect', x: -40, y: 25, w: 450, h: 1, color: 'grey' } ], fit: [10, 1000], alignment: 'center' }
      ],
    }
  }

  isSubcollection(subcollection){
    return (
      isNonNullObject(subcollection) &&
        subcollection.hasOwnProperty('type') &&
        subcollection.type === 'subcollection' &&
        subcollection.hasOwnProperty('sections') &&
        isList(subcollection.sections)

    )
  }

  renderSubcollection(subcollection){
    if(!this.isSubcollection(subcollection)) return null;
    const collection_elements = this.mapStructureElements(subcollection.sections);

    return {
      stack: [
        this.getNameElement(subcollection.name, 15),
        {
          stack: collection_elements,
          margin: only_left_margin_value
        }
      ]
    }
  }

  renderNamelessGrouping(grouping){
    const group_elements = this.mapStructureElements(grouping.sections);

    return {
      stack: group_elements,
      margin: only_left_margin_value
    }
  }

  getNameElement(name, fontSize=14){
    return {
      text: name,
      fontSize: fontSize,
      alignment: 'left',
      bold: true,
      margin: [0, 20, 0, 5]
    }
  }

  mapStructureElements(elements){
    return elements.map(
      (element) => {
        return this.renderStructureElement(element);
      }
    )
  }

  renderMultiChoice(multi_choice){
    const name_element = this.getNameElement(multi_choice.name);
    const option_elements = this.mapStructureElements(multi_choice.options);

    return {
      stack: [
        name_element,
        {
          ul: option_elements,
          margin: only_left_margin_value
        }
      ],
    }
  }

  renderSingleChoice(single_choice){
    const name_element = this.getNameElement(single_choice.name);
    const option_elements = single_choice.options.map((option)=>{return this.renderStructureElement(option)});
    return {
      stack: [
        name_element,
        {
          stack: [
            {
              ul: option_elements,
              margin: only_left_margin_value,
            },
          ]
        },
      ],
    }
  }

  isOption(option){
    return (
      isNonNullObject(option) &&
        option instanceof Option &&
        option.hasOwnProperty('name') &&
        isString(option.name)
    )
  }

  renderOption(option){
    let section_if_selected = option.getConditionalSection(this.proposal);
    let conditional_section = null
    if (section_if_selected){
      conditional_section = 
        {
          stack: [
            this.renderStructureElement(section_if_selected)
          ],
        }
    }
    return {
      stack: [
        {
          text: option.name,
          fontSize: 13,
          lineHeight: 1.3,
          decoration: option.isSelected(this.proposal) ? null : 'lineThrough',
          color: option.isSelected(this.proposal) ? null : 'grey'
        },
        conditional_section
      ],
    }
  }
}

export class UKE_PDF {
  constructor(pdf_name, sections, proposal = null){
    //console.log(JSON.stringify(proposal))
    this.docDef = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [60, 120, 60, 60],
      header: this.buildHeader,
      content: this.buildContent(sections, proposal), 
      info: {
        title: pdf_name,
        keywords: 'hchs proposal',
        producer: 'hchs project',
      }
    }
  }

  buildContent(sections, proposal){
    let title = proposal instanceof Proposal ? proposal.getProposalAttribute('intro_project_title') : "no title given";
    const content = [
      {
        text: "HCHS Research Proposal:",
        fontSize: 20,
        bold: true,
        alignment: 'left',
      },
      {
        text: title,
        fontSize: 18,
        bold: true,
        italics: true,
        alignment: 'right',
        margin: [0, 10, 0, 40]
      }
    ];

    return content.concat(this.buildSections(sections, proposal));
  }

  buildHeader(currentPage, pageCount, pageSize){
    return [
      {
        svg: UKE_HCHS_LOGO,
        margin: [25, 25],
        width: pageSize.width - 50,
        height: ((currentPage === 1 && 100) || 65)
      },
      {text: "\n"}
    ]
  }

  buildSections(sections, proposal){
    if(!(proposal instanceof Proposal) || proposal === null) return [];
    let content = [];
    for (const section of sections){
      if (! section.hasOwnProperty('type')) continue;
      let renderer = new StructureElementRenderer(proposal instanceof Proposal ? proposal : null);
      const rendered = renderer.renderStructureElement(section);
      if (!rendered) continue;
      content.push(rendered);
    }
    return content;
  }

  buildTextSection(section){
    const buffer_line = {
      text: "\n"
    };

    const only_left_margin_value = [15, 0, 0, 0];

    return {
      stack: [
        {
          text: section.name,
          fontSize: 17,
          alignment: 'left',
          bold: true,
          lineHeight: 1.5,
        },
        {
          text: section.content,
          fontSize: 13,
          alignment: 'justify',
          //margin: only_left_margin_value,
          lineHeight: 1
        },
        buffer_line
      ],
      lineHeight: 1.5
    }
  }

  open(){
    pdfMake.createPdf(this.docDef).open();
  }

  download(){
    pdfMake.createPdf(this.docDef).download(this.docDef.info.title);
  }
}

