import styled from "styled-components";

export const SingleIconWidgetButton = styled.button`
    width: 100%;
    height: 100%;
    font-size: 24;
    border-radius: 30px;
    background-color: white;
    border-style: solid;
    border-color: gray;
`;

const MenuAreaButton = styled.button`
    font-size: 14px;
    margin-top: 30px;
    padding: 16px 20px;
    border-radius: 26px;
    border: 1px solid #d4d3e8;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
    background: #fff;
    color: #4c489d;
    box-shadow: 2px 2px 20px #5c5696;

    &:active,
    &:focus,
    &:hover {
        box-shadow: 2px 2px 20px #2a1ea6;
    }
`;

const AdjustRight = styled.span`
    position: relative;
    font-size: 24px;
    margin-left: auto;
    color: #7875b5;
`;

const AdjustLeft = styled.span`
    position: relative;
    font-size: 24px;
    color: #7875b5;
    padding-right: 10px;
`;

export function MenuAreaButtonIconLeft(props){
    return (
        <MenuAreaButton 
            onClick={props.callback}
            type="button"
            {...props}
        >
            <AdjustLeft>{props.icon}</AdjustLeft>
            <span>{props.button_text}</span>
        </MenuAreaButton>
    )
}

export function MenuAreaButtonIconRight(props){
    return (
        <MenuAreaButton 
            onClick={props.callback}
            type="button"
            {...props}
        >
            <span>{props.button_text}</span>
            <AdjustRight>{props.icon}</AdjustRight>
        </MenuAreaButton>
    )
}