import { css } from "styled-components";
import styled from "styled-components";
import { GridElement } from "../text_display/main_content_text_styling";
import { OverflowContainerVertical, RowGrid } from "../divider and frames/layouts";
import { HeaderSmall } from "../text_display/main_content_text_styling";


export const PanelBorderStyling = css`
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #0f0000;
`

export const LinkPanelStyling = styled(RowGrid)`
  ${PanelBorderStyling};
  ${OverflowContainerVertical};
`

const VariableListPanelStylings = css`
  padding: 15px;
  ${PanelBorderStyling}
  ${OverflowContainerVertical}
`

export const OuterPanel = styled(RowGrid)`
grid-column: ${props => props.grid_column};
${VariableListPanelStylings}
`

export function SelectedVariablesPanel({selectedVariables}) {
  return (
    <OuterPanel template_rows={"auto 1fr"}>
      <HeaderSmall text={"Selected Variables"} />
      {selectedVariables}
    </OuterPanel>
  )
}
