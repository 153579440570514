import {
  deepEqual,
  isNonNullObject,
  isOneOf,
  structuredClone,
} from "./helper_functions";
import { isString, isList } from "./helper_functions";

export class Node {
  constructor(node_id, node_details, node_type, node_visibility, node_display_name) {
    this.id = this.assignNodeId(node_id);
    this.initial_details = this.assignInitialDetails(node_details);
    this.details = structuredClone(this.initial_details);
    this.type = node_type;
    this.visibility = node_visibility;
    this.display_name = node_display_name;
  }

  ArgError(method, arg_name, arg_expectation, arg_actual) {
    return new Error(
      `For Object node of type Node:: node.${method}(${arg_name}):: expect ${arg_name} ${arg_expectation} - ${arg_name} is ${JSON.stringify(
        arg_actual
      )}`
    );
  }

  assignNodeId(node_id) {
    if (!isString(node_id)) {
      throw this.ArgError("assignNodeId", "node_id", "to be String", node_id);
    }
    return node_id;
  }

  assignInitialDetails(node_details) {
    if (!isList(node_details)) {
      throw this.ArgError(
        "assignInitialDetails",
        "node_details",
        "to be List",
        node_details
      );
    }
    let details = [];
    for (const detail of node_details) {
      if (Node.isNodeDetail(detail)) {
        details.push(structuredClone(detail));
      }
    }
    return details;
  }

  getNodeCopy() {
    return new Node(
      this.id,
      structuredClone(this.details),
      this.type,
      this.visibility,
      this.display_name
    );
  }

  // --- class methods ---
  static isNodeDetailOld(maybe_detail) {
    return (
      typeof maybe_detail === "object" &&
        maybe_detail !== null &&
        maybe_detail.hasOwnProperty("attributeName") &&
        maybe_detail.hasOwnProperty("attributeContent") &&
        isString(maybe_detail.attributeName)
    );
  }
  
  static isNodeDetail(maybe_detail) {
    return (
      typeof maybe_detail === "object" &&
        maybe_detail !== null &&
        maybe_detail.hasOwnProperty("attributeName") &&
        maybe_detail.hasOwnProperty("attributeContent") &&
        isString(maybe_detail.attributeName)
    );
  }

  static getDisplayName(node){
    let nodeData = Node.getNodeData(node);
    return Node.getDesciptiveName(nodeData);
  }

  //tested
  static isVMContext(node){
    let nodeData = Node.getNodeData(node);
    return (
      isString(nodeData.id) && 
      nodeData.type === "context" ||  
      isOneOf(
        nodeData.id, 
        [
          "VM_FB_VM_FB", 
          "VM_Fragebogen_Benjamin", 
          "VM_FB_DIFE_Inworks_VM", 
          "VM_HCHS_Soarian_ALL_RS_@bearbeitet",
          "VM_Gerätedaten ALL",
          "Start / Overview",
          "Root",
          "Gerätedaten",
          "Fragebögen",
          "Untersuchungsergebnisse",
          "Sekundärvariablen"
        ]) 
      || nodeData.id.startsWith("Var-Manual-Kontext_"));
  }

  static isVariable(node, excludedVariableNames=[]){
    let nodeData = Node.getNodeData(node);
    let id = nodeData.id;
    if (excludedVariableNames.includes(id)) return false;
    return (isString(nodeData.id) && nodeData.id !== "" && !Node.isVMContext(nodeData));
  }

  static compareDisplayName(node1, node2){
    return Node.getDisplayName(node1).localeCompare(Node.getDisplayName(node2));
  }

  static sortNodes(node_list){
    return node_list.sort(this.compareDisplayName);
  }

  static isNodeDetails(maybe_details) {
    if (!isList(maybe_details)) return false;

    for (const maybe_detail of maybe_details) {
      if (!Node.isNodeDetail(maybe_detail)) return false;
    }
    return true;
  }

  static detailContainsSearchString(detail, searchString){
    searchString = searchString.toLowerCase();
    if (!this.isNodeDetail(detail)) return false;
    if (detail.attributeName.toLowerCase().includes(searchString)) return true;
    if (detail.attributeContent.toLowerCase().includes(searchString)) return true;
    return false;
  }

  static searchStringMatchInDetails(searchString, details){
    let isMatch = false;
    while(!isMatch && details.length > 0){
      let detail = details.pop();
      isMatch = this.detailContainsSearchString(detail, searchString);
    }
    return isMatch;
  }

  static nodeMatchesSearch(node, searchTermList){
    if (!this.isNodeLike(node)) return false;
    let details = Node.getNodeData(node).details;
    //check if a search term has match in id or display_name
    //only check not already matched elements for matches within details
    let reducedList = searchTermList.filter((elem)=>{
      elem = elem.toLowerCase();
      return !(node.id.toLowerCase().includes(elem) || node.display_name.toLowerCase().includes(elem));
    })

    let hasMissmatch = false;
    while(reducedList.length > 0 && !hasMissmatch){
      let searchString = reducedList.pop();
      hasMissmatch = !this.searchStringMatchInDetails(searchString, structuredClone(details));
    }
    return !hasMissmatch;
  }

  static filterEmptyDetails(details){
    let filteredDetails = [];
    for (const detail of details){
      if (!Node.isNodeDetail(detail)) throw new Error("For Node.filterEmptyDetails - expected list of NodeDetails");
      if (!Node.isEmptyDetail(detail)){
        filteredDetails.push(detail)
      }
    }
    return filteredDetails
  }

  static isEmptyDetail(detail){
    const pattern = /\S/;
    return !pattern.test(detail.attributeContent);
  }

  static getDesciptiveName(nodeLike) {
    return nodeLike.display_name;
  }

  static appendDetailContents(content1, content2){
    return `${content1}\n\n${content2}`;
  }

  static mergeDetails(details1, details2){
    let details_dict = details1.reduce(
      (akk, det) => ({...akk, [det.attributeName]: det }), 
      {}
    )
    for (const detail2 of details2) {
      if(!details_dict.hasOwnProperty(detail2.attributeName)){
        details_dict[detail2.attributeName] = {
          attributeName: detail2.attributeName,
          attributeContent: detail2.attributeContent,
          visibility: detail2.visibility
        }
      }
      else{
        details_dict[detail2.attributeName] = {
          attributeName: detail2.attributeName,
          attributeContent: Node.appendDetailContents(
            details_dict[detail2.attributeName].attributeContent, 
            detail2.attributeContent),
          visibility: detail2.visibility
        }
      }
    }
    return Object.values(details_dict);
  }

  static appendDetailContents(content1, content2){
    return `${content1}\n\n${content2}`;
  }

  static mergeDetails(details1, details2){
    let details_dict = details1.reduce(
      (akk, det) => ({...akk, [det.attributeName]: det }), 
      {}
    )
    for (const detail2 of details2) {
      if(!details_dict.hasOwnProperty(detail2.attributeName)){
        details_dict[detail2.attributeName] = {
          attributeName: detail2.attributeName,
          attributeContent: detail2.attributeContent,
          visibility: detail2.visibility
        }
      }
      else{
        details_dict[detail2.attributeName] = {
          attributeName: detail2.attributeName,
          attributeContent: Node.appendDetailContents(
            details_dict[detail2.attributeName].attributeContent, 
            detail2.attributeContent),
          visibility: detail2.visibility
        }
      }
    }
    return Object.values(details_dict);
  }

  static isNodeLike(node) {
    return(
      isNonNullObject(node) ?? 
        (
          Node.isNode(node) || 
            (
              node.hasOwnProperty("id") &&
              node.hasOwnProperty("details")  
            )
        )
    )
  }

  static isNode(node) {
    return node instanceof Node;
  }

  static nodesEqual(nodeLike_1, nodeLike_2) {
    if (!Node.isNode(nodeLike_1) && !Node.isNodeLike(nodeLike_1)) return false;
    if (!Node.isNode(nodeLike_2) && !Node.isNodeLike(nodeLike_2)) return false;
    return deepEqual(
      Node.getNodeData(nodeLike_1),
      Node.getNodeData(nodeLike_2)
    );
  }

  static getNodeData(maybe_node) {
    if (Node.isNode(maybe_node)) return maybe_node.getNodeCopy();
    if (Node.isNodeLike(maybe_node)) return maybe_node;

    throw new Error(`For Node.getNodeData(maybe_node) expect maybe_node to be instance of Node or to evaluate to true using Node.isNodeLike(maybe_node)
    \t maybe_node actual = ${JSON.stringify(maybe_node)}`);
  }
  // ^^^ class methods ^^^
}
